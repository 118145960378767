import React from 'react'
import Layout from '../../components/layout'
import Banner from '../../components/Banner'
import Heading from '../../components/Heading'
import Background from "../../components/images/banner.jpg"
import './recipes.scss'
import { graphql } from 'gatsby'
import { useSeo } from '../../hooks/useSeo'
import Seo from '../../components/seo'

function RecipesPage({ data }) {
    const aboutContent = data && data.allWpAbout.edges.length >= 1
        ? data.allWpAbout.edges[0].node.acf : null

    const banner = aboutContent.component.filter(ab => ab.fieldGroupName === "about_Acf_Component_MainBanner")
    const textWithHeading = aboutContent.component.filter(ab => ab.fieldGroupName === "about_Acf_Component_TextWithHeading")

    const headingTop = textWithHeading && textWithHeading.length >= 1
        ? textWithHeading[0].heading 
        : null

    const headingBottom = textWithHeading && textWithHeading.length >= 1
        ? textWithHeading[1].heading 
        : null

    const form = textWithHeading && textWithHeading.length >= 1 
        ? textWithHeading[1].text 
        : null;
    const {metaDescription,title} = useSeo("cG9zdDoxMjY5Mw==")
    return (
        <Layout>
            <Seo title={title} description={metaDescription}/>
            <Banner
                banner={banner}
                btnNext={true}
            />
            <div className="recipes section">
                <Heading
                    title={headingTop}
                    className="pd-row-2"
                />
                <div className="pd-row"></div>
                <Heading title={headingBottom} className="pd-row-2" />
                <div className="container pd-row" dangerouslySetInnerHTML={{ __html: form }} />
            </div>
        </Layout>
    )
}

export default RecipesPage

export const GET_ABOUT_US = graphql`
    query getRecipes {
        allWpAbout(filter: {
            slug: {
                eq: "recipes"
            }
        }) {
            edges {
                node {
                    title
                    slug
                    acf {
                        component {
                            ... on WpAbout_Acf_Component_MainBanner {
                                fieldGroupName
                                heading
                                text
                                image {
                                    sourceUrl
                                }
                            }
                            ... on WpAbout_Acf_Component_TextWithMedia {
                                fieldGroupName
                                subHeading
                                heading
                                text
                                media {
                                    mediaItemUrl
                                    mimeType
                                }
                            }
                            ... on WpAbout_Acf_Component_TextWithHeading {
                                fieldGroupName
                                subHeading
                                heading
                                text
                            }
                            ... on WpAbout_Acf_Component_Testimonial {
                                fieldGroupName
                                title
                                quote
                                author
                            }
                        }
                    }
                }
            }
        }
    }
`